import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../constants";

type Props = {
  customBreakpoint?: string;
};

const ContentContainer = styled.div<Props>`
  max-width: ${(props) =>
    !!props.customBreakpoint ? props.customBreakpoint : MOBILE_BREAKPOINT};
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 24px;
  margin: 0px auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 24px;
  align-self: center;

  @media (max-width: ${(props) =>
      !!props.customBreakpoint ? props.customBreakpoint : MOBILE_BREAKPOINT}) {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    align-self: flex-start;
  }
`;

export { ContentContainer };
