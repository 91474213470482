import React from "react";
import styled from "styled-components";
import { NextPage } from "next";
import {
  Column,
  ContentContainer,
  Row,
  StyledButton,
  StyledScreen,
} from "../components/styles";

import LandingImage from "../assets/landing-image.svg";
import Image from "next/image";
import { TYPEFORM_URL } from "../constants";

const LANDING_PAGE_BREAKPOINT = "1296px";

const LandingScreen = styled(StyledScreen)`
  background-color: ${(props) => props.theme.colors.container.primary};

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    padding-top: 0px;
  }
`;

const PageContentContainer = styled(ContentContainer)`
  justify-content: center;

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    justify-content: flex-start;
  }
`;

const PageRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const PageColumn = styled(Column)`
  flex: 1;
  margin-right: 24px;

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    align-items: center;
    margin-right: 0px;
  }
`;

const Heading = styled.h2`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => props.theme.fontSize.title};
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 0;

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    font-size: ${(props) => props.theme.fontSize.large};
    text-align: center;
    margin-top: 37px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => props.theme.fontSize.heading};
  font-weight: 400;
  margin-top: 24px;

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    font-size: ${(props) => props.theme.fontSize.title};
    text-align: center;
    margin-top: 37px;
  }
`;

const Body = styled.h1`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: 400;
  margin-bottom: 36px;
  line-height: 28px;
  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    font-size: ${(props) => props.theme.fontSize.regular};
    margin-bottom: 36px;
    text-align: center;
    max-width: 600px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: auto;
  margin-top: 0px;

  @media (max-width: ${LANDING_PAGE_BREAKPOINT}) {
    max-width: 700px;
    margin-top: 32px;
  }
`;

const Landing: NextPage = () => {
  const onContactUsClick = () => {
    window.open(TYPEFORM_URL);
  };

  return (
    <LandingScreen customBreakpoint={LANDING_PAGE_BREAKPOINT}>
      <ContentContainer customBreakpoint={LANDING_PAGE_BREAKPOINT}>
        <Heading>Crypto-forms.io</Heading>
        <PageContentContainer customBreakpoint={LANDING_PAGE_BREAKPOINT}>
          <PageRow>
            <PageColumn>
              <Title>Get verified insights from your Web3 community</Title>
              <Body>
                Create and share forms to get the most out of your community
                with verified wallet submission.
              </Body>
              <StyledButton onClick={onContactUsClick}>
                Get in touch
              </StyledButton>
            </PageColumn>
            <ImageContainer>
              <Image src={LandingImage} height={900} />
            </ImageContainer>
          </PageRow>
        </PageContentContainer>
      </ContentContainer>
    </LandingScreen>
  );
};

export default Landing;
