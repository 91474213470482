import styled from "styled-components";
import { CTA_HEX_OPACITY_SUFFIX } from "../../constants";

const StyledCta = styled.h3`
  color: ${(props) =>
    `${props.theme.colors.text.primary}${CTA_HEX_OPACITY_SUFFIX}`};

  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: 400;
  margin: 0px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  :hover {
    color: ${(props) => props.theme.colors.text.primary};
    text-decoration: underline;
  }
`;

export { StyledCta };
