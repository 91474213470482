import styled from "styled-components";
import { BUTTON_HIGHLIGHT_HEX_OPACITY_SUFFIX } from "../../constants";

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 100px;
  display: flex;
  padding: 6px;
  cursor: pointer;
  width: fit-content;

  :hover {
    background-color: ${(props) =>
      `${props.theme.colors.text.primary}${BUTTON_HIGHLIGHT_HEX_OPACITY_SUFFIX}`};
  }
`;
