import { v4 as uuidv4 } from "uuid";

export const METAMASK_URL = "https://metamask.io/";

export const SIGNING_MESSAGE = [
  "Crypto forms is requesting your signature to allow you to login.",
  "We need to do this to prove you are the owner of this wallet.",
  "Don't worry this will not cost you any ETH",
  `Generated code for security: ${uuidv4()}`,
].join("\n\n");

export const BUTTON_HIGHLIGHT_HEX_OPACITY_SUFFIX = "1A";
export const CTA_HEX_OPACITY_SUFFIX = "A6";
export const BACKGROUND_IMAGE_OPACITY = "CC";
export const MOBILE_BREAKPOINT = "771px";
export const HAPE_BACKGROUND_IMAGE_URL =
  "https://images.crypto-forms.io/holding/hapes.jpg";
export const TYPEFORM_URL = "https://do3g2v3m76f.typeform.com/to/JUNLNcfn";
// export const IS_FORM_CLOSED = process.env.NEXT_PUBLIC_IS_FORM_CLOSED === "true";
export const IS_FORM_CLOSED = true;
