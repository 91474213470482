import styled from "styled-components";

export const StyledCard = styled.div`
  background-color: ${(props) => props.theme.colors.container.secondary};
  border: 1px solid ${(props) => props.theme.colors.container.secondaryBorder};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-self: start;
  width: 100%;
`;
