import styled from "styled-components";

export const StyledInput = styled.input`
  border: none;
  background: ${(props) => props.theme.colors.container.tertiary};
  color: ${(props) => props.theme.colors.text.primary};
  border-bottom: 1px solid
    ${(props) => props.theme.colors.container.tertiaryBorder};
  padding: 12px;
  min-height: 40px;
  font-size: ${(props) => props.theme.fontSize.regular};
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  flex: 1;

  :focus {
    border: none;
    outline: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.text.primary};
  }
`;
