import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../constants";

type Props = {
  customBreakpoint?: string;
};

const StyledScreen = styled.div<Props>`
  display: flex;
  background-color: ${(props) => props.theme.colors.container.primary};
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;

  @media (max-width: ${(props) =>
      !!props.customBreakpoint ? props.customBreakpoint : MOBILE_BREAKPOINT}) {
    padding: env(safe-area-inset);
    height: 100vh;
    min-height: -webkit-fill-available;
    width: -webkit-fill-available;
  }
`;

export { StyledScreen };
