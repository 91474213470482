import styled from "styled-components";
import { BUTTON_HIGHLIGHT_HEX_OPACITY_SUFFIX } from "../../constants";

interface StyledButtonProps {
  isDisabled?: boolean;
}

export const StyledButton = styled.div<StyledButtonProps>`
  cursor: pointer;
  color: ${(props) => props.theme.colors.text.secondary};
  background-color: ${(props) =>
    props.isDisabled
      ? `${props.theme.colors.action.primary}${BUTTON_HIGHLIGHT_HEX_OPACITY_SUFFIX}`
      : props.theme.colors.action.primary};
  border: none;
  border-radius: 4px;
  display: flex;
  padding: 10px 24px;
  width: fit-content;
  min-height: 20px;
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: 300;
  user-select: none;
  transition: all 0.4s ease;
  transform: translateY(0px) scale(1);

  :hover {
    background-color: ${(props) =>
      props.isDisabled
        ? `${props.theme.colors.action.primary}${BUTTON_HIGHLIGHT_HEX_OPACITY_SUFFIX}`
        : props.theme.colors.action.primaryHighlight};
  }

  :active {
    transform: translateY(2px) scale(0.99);
  }
`;
